jQuery(document).ready(function ($) {
  $(".custom-person-slider").slick({
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
        },
      },
    ],
  });
  $(".custom-blog-carousel").slick({
    slidesToShow: 3.5,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  });
  $(".reviews-carousel").slick({
    slidesToShow: 3.2,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    autoplay: false,
    dots: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  });
});

/* jQuery(document).ready(function ($) {
  function initializeSlick() {
      if ($(window).width() < 768) {  // Cible les écrans de moins de 768px
          if (!$('.product-gallery-slider').hasClass('slick-initialized')) {
              $('.product-gallery-slider').slick({
                  dots: false,  // Affiche les points de navigation
                  arrows: false,  // Désactiver les flèches
                  infinite: false,  // Boucle infinie
                  slidesToShow: 1.3,
                  slidesToScroll: 1,
                  centerMode: true,
                  centerPadding: '15px',
              });
          }
      } else {
          if ($('.product-gallery-slider').hasClass('slick-initialized')) {
              $('.product-gallery-slider').slick('unslick');  // Détruit le slick si ce n'est plus mobile
          }
      }
  }

  initializeSlick();

  // Initialise Slick au chargement de la page et sur redimensionnement
  $(window).on('load resize', function () {
      initializeSlick();
  });

}); */
